.react-calendar {
  border: none;
  font-family: "Roboto";
}

.react-calendar__month-view__weekdays__weekday {
  color: $text;
}

.react-calendar__tile {
  color: $text;
  background-color: transparent !important;
  position: relative;

  &:hover {
    cursor: default !important;
  }
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label__labelText--from {
  color: $text;
}