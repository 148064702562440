.login{

  &-btn{
    font-family: Roboto;
    font-size: 1.2rem;
    cursor: pointer;
    background: none;
    border: none;
    padding: 1rem 2rem;

    &:hover{
      cursor: pointer;
    }
  }

  &-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: Roboto;
      width: 18rem;
      background-color: white;
      padding: 3rem;

      &__title{
        margin-bottom: 3rem;
        color: $text;
      }
    
      &__input {
        padding: 0.5rem 0;
        border: none;
        border-bottom: 1px solid $lines;
        margin-bottom: 1rem;
        outline: none;
        width: 100%;
      }
    
      &__btn {
        margin-top: 2rem;
        align-self: center;

        &-submit{
          border: 1px solid $primaryNormal;
          color: $primaryNormal;
          padding: 0.6rem 2.4rem;
          background-color: transparent;

          &:hover {
            background-color: $primaryLight;
            color: white;
            cursor: pointer;
          }
        }
      }


    }
}

.error{
  border-bottom: 1px solid red;

  &-msg{
    color: red;
  }
}