.react-paginate {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.react-paginate li {
  display: flex;
  list-style: none;
  margin: 0.3rem;
  text-align: center;
  cursor: pointer;

  & > a {
    padding: 0.2rem;
    min-width: 1rem;
    height: 1rem;

    &:hover {
      color: hsl(210deg, 90%, 60%); // primaryLight
    }
  }
}

.react-paginate .selected a {
  color: black;
  cursor: default;
  font-weight: bold;

  &:hover {
    color: black;
  }
}

.react-paginate li.disabled a {
  color: gray;
  cursor: default;

  &:hover {
    color: gray;
  }
}
