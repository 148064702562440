@font-face {
  font-family: RobotoLight;
  src: url(../assets/fonts/Roboto/Roboto-Light.ttf);
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: RobotoBold;
  src: url(../assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: RobotoBlack;
  src: url(../assets/fonts/Roboto/Roboto-Black.ttf);
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RobotoItalic;
  src: url(../assets/fonts/Roboto/Roboto-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}

// TODO Repeated fonts temporary to mitigate bad definition error
// There are variables in the theme defining font families for
// the job of weights.
// Code should be refactored to minimize style specification:
// - Only font families should be used for a different font (from base)
// - Only weight/style should be specified to change weight/style
// - After that there will be less inside-component tweaks and duplicated
//   font entries could be removed
