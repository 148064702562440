.carousel-root {
  align-self: start;
}

.control-dots {
  background-color: $text;
  margin: 0 !important;
  transform: translateY(100%);
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel.carousel-slider {
  overflow: visible !important;
}

.selected {
  display: grid;
  align-items: flex-start;
}

.carousel .carousel-status {
  position: absolute;
  text-shadow: none !important;
  color: $text;
  font-family: Roboto;
}

.thumbs-wrapper{
  overflow: unset !important;
}